import React from "react"
import * as s from "./index.module.scss"
import Layout from "../../Layout"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { graphql, Link } from "gatsby"
import Sidebar from "./_Sidebar"
import get from "lodash/get"
import { format } from "date-fns"

const BlogPage = ({ data }) => {
  const list = get(data, "allPrismicBlogPost.nodes")
  return (
    <Layout>
      <Seo title="Blog" />
      <Breadcrumbs title={"Blog"} />

      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <ul className={s.blog_list}>
              {list.map(
                ({
                  id,
                  uid,
                  data: {
                    post_date,
                    excerpt,
                    post_title: { text: title },
                    image,
                  },
                }) => {
                  const imageUrl = get(image, "thumbnails.thumbnail.url", null)
                  return (
                    <li key={id} className={"mb-5"}>
                      <h3>{title}</h3>
                      <time>{post_date}</time>
                      {imageUrl && <img src={imageUrl} alt={title} />}
                      <p>{excerpt}</p>
                      <Link className={"btn btn-primary"} to={`/blog/${uid}`}>
                        Read article
                      </Link>
                    </li>
                  )
                }
              )}
            </ul>
          </div>
          <div className="col-lg-4 d-none d-lg-block">
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default BlogPage

export const query = graphql`
  query BlogPageQuery {
    allPrismicBlogPost(sort: { order: DESC, fields: data___post_date }) {
      nodes {
        data {
          excerpt
          post_title {
            text
          }
          post_date(formatString: "MMMM do, yyyy")
          image {
            thumbnails {
              thumbnail {
                url
              }
            }
            url
          }
        }
        id
        uid
        last_publication_date
      }
    }
  }
`
